import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, TextField, FormControl, FormLabel, Button, InputAdornment, IconButton, Modal, Divider } from "@mui/material";
import { imgCancle, imgCheck, imgCheckGrey, imgCheckMark, imgLogin, imgLogo, imgClose, imgCard, imgOk } from "./assets";
import { Formik, Form, Field } from 'formik';
import { Visibility as VisibilityIcon , VisibilityOff as VisibilityOffIcon, Pause as PauseIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import * as Yup from 'yup';
import { validatePassword, getRequirements, getErrorAndHelperText, getRequirementImageKey, validateExpirationYear, validateExpirationMonth } from "../../../components/src/Utility";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}
// Customizable Area End

import CreatePasswordControllerWeb, {
  Props,
  configJSON,
} from "./CreatePasswordController.web";

export default class CreatePassword extends CreatePasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getInitialValues() {
    return {
      password:"",
      confirmPassword:"",
    };
  }

  passwordValidationSchema = Yup.string()
  .required('Password is required')
  .test('password-requirements', 'Password does not meet the requirements', validatePassword);

  validationSchema = Yup.object().shape({
    password: this.passwordValidationSchema,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Please enter the password again')
      .required('Confirm Password is required'),
  });

  images = {
    imgCheck: imgCheck,
    imgCancel: imgCancle,
    imgCheckGrey: imgCheckGrey,
  };

  validationSchemaForCardDetails = Yup.object({
    cardholderName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Cardholder Name should only contain letters")
    .required('Cardholder Name is required'),
    expiration: Yup.string()
    .test('valid-expiration', 'Invalid format. Use MM/YYYY.', validateExpirationYear)
    .test('month-range', 'Month must be between 01 and 12.', validateExpirationMonth)
    .required('Expiration is required'),
    cardNumber: Yup.string()
    .matches(/^[\d\s]+$/, "Card Number must contain only numbers")
    .required("Card Number is required"),
    cvv: Yup.string()
    .matches(/^\d+$/, 'CVV must contain only numbers')
    .required('CVV is required'),
    country: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Country Name should only contain letters")
    .required('Country Name is required'),
  });

  getInitialValuesForCardDetails() {
    return {
      cardholderName: "",
      cardNumber: "",
      expiration: "",
      cvv: "",
      country: "",
    };
  }

  validationSchemaForAddress = Yup.object({
    address: Yup.string().required('Address is required'),
    city: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "City Name should only contain letters")
    .required('City Name is required'),
    state: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "State Name should only contain letters")
    .required('State Name is required'),
    zip: Yup.string()
    .matches(/^\d+$/, 'Zip Code must contain only numbers')
    .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits")
    .required('ZipCode is required'),
    country: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Country Name should only contain letters")
    .required('Country Name is required'),
  });

  getInitialValuesForAddress() {
    return {
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
  }
  
  renderIf = <P,>(condition: boolean, component: React.ReactElement<P>) => {
    return condition ? component : null;
  };

  renderConditionalComponent = <P, Q>(
    condition: boolean,
    trueComponent: React.ReactElement<P>,
    falseComponent: React.ReactElement<Q>
  ) => {
    return condition ? trueComponent : falseComponent;
  };

  getColor = (isPremiumPlanSelected : boolean) => {
    return isPremiumPlanSelected ? "#F69544" : "#94A3B8";
  };

  getImageSrc = (isPremiumPlanSelected : boolean) => {
    return isPremiumPlanSelected ? imgCheck : imgCancle;
  };

  renderPlanBox(planById: SubscriptionPlan | null, isPremiumPlanSelected: boolean) {
    return (
      <Box sx={styles.planBox}>
        <Typography
          sx={{
            ...styles.cardModalPlanText,
            color: this.getColor(isPremiumPlanSelected),
          }}
        >
          {this.capitalizeFirstLetter(planById?.name)}
        </Typography>
        <Typography sx={styles.cardModalPlanPriceText}>
          ${planById?.price}
        </Typography>
        {this.renderConditionalComponent(
          isPremiumPlanSelected,
          <Typography sx={styles.cardModalPlanPriceDescription}>
            Up to{" "}
            <span style={styles.cardModalCptCodeText}>
              Unlimited CPT Code
            </span>
          </Typography>,
          <Typography sx={styles.cardModalPlanPriceDescription}>
            Basic feature for up to{" "}
            <span style={styles.cardModalCptCodeText}>
              {planById?.cpt_code} CPT Code
            </span>
          </Typography>
        )}
        <Box display={"flex"} flexDirection={"column"} marginTop={"50px"}>
          <Box display={"flex"} alignItems={"center"} marginBottom={"8px"}>
            <img src={this.getImageSrc(isPremiumPlanSelected)} />
            <Typography sx={styles.cardModalMainTextDescription}>
              Access to Year Over Year Comparison.
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <img src={this.getImageSrc(isPremiumPlanSelected)} />
            <Typography sx={styles.cardModalMainTextDescription}>
              Access to Similar CPT Codes.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  capitalizeFirstLetter = (text : string | undefined) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      showPassword,
      showConfirmPassword,
      confirmPasswordError,
      fieldBlurred,
      passwordSetSuccess,
      isImageVideoModalVisible,
      isPlaying,
      isHovered,
      isVideoEnded,
      isPlanModalVisible,
      isPlan1Hovered,
      isPlan2Hovered,
      isPlan3Hovered,
      isCardDetailsModal,
      planById,
      isPremiumPlanSelected,
      isAdressModal,
      isSkipForNowClicked,
      isVideoType,
      signupVideoUrlResponse,
      freePlan,
      basicPlan,
      premiumPlan,
      cardNumberError,
      expirationError,
      cvvError,
      isPaymentDone
    } = this.state;
    const initialValues = this.getInitialValues();
    const requirements = getRequirements();
    const modalStyle = modalStyles();
    const modal1Style = modalStylesConditioanal(isPlan1Hovered);
    const modal2Style = modalStylesConditioanal(isPlan2Hovered);
    const modal3Style = modalStylesConditioanal(isPlan3Hovered);
    const initialValuesForCardDetails = this.getInitialValuesForCardDetails();
    const initialValuesForAddress = this.getInitialValuesForAddress();
    return (
      <Box sx={styles.createPasswordMainContainer}>
        <Grid container spacing={2} sx={styles.mainGridCreatePassword}>
          <Grid
            item
            sx={styles.imageContainerCreatePassword}
            lg={6}
            xl={6}
            sm={12}
            xs={12}
            md={6}
          >
            <Box sx={styles.imageWrapperCreatePassword}>
              <img
                src={imgLogin}
                alt={configJSON.loginAlt}
                style={styles.imageCreatePassword}
              />
              <Box sx={styles.logoCreatePassword}>
                <img src={imgLogo} alt="LogoCreatePassword" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xl={6}
            lg={6}
            xs={12}
            sx={styles.secondGridCreatePassword}
          >
            <Box sx={styles.formContainerCreatePassword}>
              {this.renderConditionalComponent(
                passwordSetSuccess,
                <Box sx={styles.successfullyCreatePasswordBox}>
                  <img src={imgCheckMark} />
                  <Typography sx={styles.successfullyCreatePasswordText}>
                    You’ve successfully created your account
                  </Typography>
                  <Button
                    data-test-id="getStartedButton"
                    variant="contained"
                    sx={styles.continueButton}
                    onClick={() => this.handleClickGetStartedButton()}
                    fullWidth
                  >
                    Get Started
                  </Button>
                </Box>,
                <>
                  <Typography sx={styles.createPasswordText}>
                    Create Password
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      isValid,
                      values,
                      handleChange,
                      handleBlur,
                    }) => (
                      <Form style={styles.createPasswordForm}>
                        <FormControl
                          sx={styles.createPasswordFormControlPassword}
                        >
                          <FormLabel sx={styles.labelTextCreatePassword}>
                            Password
                          </FormLabel>
                          <Box>
                            <Field
                              as={TextField}
                              name="password"
                              variant="outlined"
                              placeholder="Enter Password"
                              type={showPassword ? "text" : "password"}
                              fullWidth
                              value={values.password}
                              onChange={handleChange("password")}
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                this.handleBlur(values.password);
                              }}
                              sx={styles.fieldStyleCreatePassword}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      data-test-id="toggle-password-visibility"
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityIcon sx={styles.iconColor} />
                                      ) : (
                                        <VisibilityOffIcon
                                          sx={styles.iconColor}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                getErrorAndHelperText(
                                  "password",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).isError
                              }
                              helperText={
                                getErrorAndHelperText(
                                  "password",
                                  errors as FormError,
                                  touched as FormErrorTouched
                                ).helperText
                              }
                            />
                          </Box>
                          <Box>
                            {values.password && !isValid && (
                              <>
                                {requirements.map((requirement, index) => {
                                  const imageKey = getRequirementImageKey(
                                    requirement,
                                    values.password,
                                    fieldBlurred
                                  );
                                  const src = this.images[imageKey];
                                  const alt = `${imageKey
                                    .replace("img", "")
                                    .toLowerCase()} icon`;
                                  return (
                                    <Box
                                      data-test-id="password-reqirements"
                                      key={index}
                                      sx={styles.passwordRequirementBox}
                                    >
                                      <img src={src} alt={alt} />
                                      <Typography sx={styles.errorText}>
                                        {requirement.label}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </>
                            )}
                          </Box>
                        </FormControl>
                        <FormControl
                          sx={styles.createPasswordFormControlPassword}
                        >
                          <FormLabel sx={styles.labelTextCreatePassword}>
                            Confirm password
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="confirmPassword"
                            variant="outlined"
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            fullWidth
                            sx={styles.fieldStyleCreatePassword}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    data-test-id="toggle-password-visibility"
                                    aria-label="toggle password visibility"
                                    onClick={
                                      this.handleClickShowConfirmPassword
                                    }
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityIcon sx={styles.iconColor} />
                                    ) : (
                                      <VisibilityOffIcon
                                        sx={styles.iconColor}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              getErrorAndHelperText(
                                "confirmPassword",
                                errors as FormError,
                                touched as FormErrorTouched,
                                confirmPasswordError
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "confirmPassword",
                                errors as FormError,
                                touched as FormErrorTouched,
                                confirmPasswordError
                              ).helperText
                            }
                          />
                        </FormControl>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={styles.continueButton}
                          fullWidth
                          disabled={!isValid}
                        >
                          Continue
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              <Modal
                open={isImageVideoModalVisible}
                data-test-id="modalVideo"
                onClose={this.handleImageVideoModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {this.renderConditionalComponent(
                  isVideoType,
                  <Box
                    sx={styles.modalInner}
                    data-test-id="videoContainer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <ReactPlayer
                      key={signupVideoUrlResponse?.media_url?.url}
                      url={signupVideoUrlResponse?.media_url?.url}
                      data-test-id="reactVideoPlayer"
                      width="100%"
                      height="100%"
                      playing={isPlaying}
                      controls={false}
                      onEnded={this.handleVideoEnded}
                    />
                    <IconButton
                      data-test-id="iconPlayPauseButton"
                      onClick={this.handleVideoClick}
                      sx={{
                        ...styles.videoIconButton,
                        opacity: isHovered ? 1 : 0,
                      }}
                    >
                      {this.renderConditionalComponent(
                        isPlaying,
                        <PauseIcon fontSize="large" />,
                        <PlayArrowIcon fontSize="large" />
                      )}
                    </IconButton>
                    {this.renderIf(
                      isVideoEnded === true,
                      <Button
                        variant="contained"
                        sx={styles.seePricingButton}
                        data-test-id="seePricingButton"
                        onClick={() => this.handleClickSeePricingButton()}
                      >
                        See Pricing
                      </Button>
                    )}
                  </Box>,
                  <Box sx={{ ...styles.modalInner, height: "500px" }}>
                    <img
                      src={signupVideoUrlResponse?.media_url?.url}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={styles.seePricingButton}
                      data-test-id="seePricingButton"
                      onClick={() => this.handleClickSeePricingButton()}
                    >
                      See Pricing
                    </Button>
                  </Box>
                )}
              </Modal>
              <Modal
                open={isPlanModalVisible}
                data-test-id="planModalClose"
                onClose={this.handleSeePricingModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle.modalPlanInner}>
                  <Box sx={modalStyle.planModalInner}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box
                        sx={modal1Style.boxBorder}
                        data-test-id="plan1Hovered"
                        onMouseEnter={this.handlePlan1Hovered}
                        onMouseLeave={this.handlePlan1Leave}
                      >
                        <Box padding={"24px"}>
                          <Typography sx={modalStyle.freePlanText}>
                            {this.capitalizeFirstLetter(freePlan?.name)}
                          </Typography>
                          <Box display={"flex"} marginBottom={"10px"}>
                            <Typography sx={modal1Style.planPriceModal}>
                              ${freePlan?.price}
                            </Typography>
                            <Typography sx={modal1Style.planPriceUnitModal}>
                              /month
                            </Typography>
                          </Box>
                          <Typography sx={modalStyle.planPriceDescription}>
                            Basic feature for up to{" "}
                            <span style={modal1Style.cptCodeText}>
                              {freePlan?.cpt_code} CPT Code
                            </span>
                          </Typography>
                        </Box>
                        <Divider style={modalStyle.dividerColor} />
                        <Box padding={"24px"}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginBottom={"23px"}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              marginBottom={"8px"}
                            >
                              <img src={imgCancle} />
                              <Typography sx={modal1Style.mainTextDescription}>
                                Access toYear Over Year Comparison.
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <img src={imgCancle} />
                              <Typography sx={modal1Style.mainTextDescription}>
                                Access to Similar CPT Codes.
                              </Typography>
                            </Box>
                          </Box>
                          <Box padding={"0px 20px"}>
                            <Button
                              variant="outlined"
                              sx={modal1Style.continueModalButton}
                              fullWidth
                              data-test-id="freePlanContinue"
                              onClick={() => this.handleSkipButton()}
                            >
                              Continue
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={modal2Style.boxBorder}
                        data-test-id="plan2Hovered"
                        onMouseEnter={this.handlePlan2Hovered}
                        onMouseLeave={this.handlePlan2Leave}
                      >
                        <Box padding={"24px"}>
                          <Typography sx={modalStyle.freePlanText}>
                          {this.capitalizeFirstLetter(basicPlan?.name)}
                          </Typography>
                          <Box display={"flex"} marginBottom={"10px"}>
                            <Typography sx={modal2Style.planPriceModal}>
                              ${basicPlan?.price}
                            </Typography>
                            <Typography sx={modal2Style.planPriceUnitModal}>
                              /month
                            </Typography>
                          </Box>
                          <Typography sx={modalStyle.planPriceDescription}>
                            Basic feature for up to{" "}
                            <span style={modal2Style.cptCodeText}>
                              {basicPlan?.cpt_code} CPT Code
                            </span>
                          </Typography>
                        </Box>
                        <Divider style={modalStyle.dividerColor} />
                        <Box padding={"24px"}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginBottom={"23px"}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              marginBottom={"8px"}
                            >
                              <img src={imgCancle} />
                              <Typography sx={modal2Style.mainTextDescription}>
                                Access toYear Over Year Comparison.
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <img src={imgCancle} />
                              <Typography sx={modal2Style.mainTextDescription}>
                                Access to Similar CPT Codes.
                              </Typography>
                            </Box>
                          </Box>
                          <Box padding={"0px 20px"}>
                            <Button
                              variant="outlined"
                              sx={modal2Style.continueModalButton}
                              fullWidth
                              data-test-id="basicPlanContinue"
                              onClick={() =>
                                this.handleClickContinueButton(basicPlan?.id)
                              }
                            >
                              Continue
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={modal3Style.boxBorder}
                        data-test-id="plan3Hovered"
                        onMouseEnter={this.handlePlan3Hovered}
                        onMouseLeave={this.handlePlan3Leave}
                      >
                        <Box padding={"24px"}>
                          <Typography sx={modalStyle.premiumPlanText}>
                          {this.capitalizeFirstLetter(premiumPlan?.name)}
                          </Typography>
                          <Box display={"flex"} marginBottom={"10px"}>
                            <Typography sx={modal3Style.planPriceModal}>
                              ${premiumPlan?.price}
                            </Typography>
                            <Typography sx={modal3Style.planPriceUnitModal}>
                              /month
                            </Typography>
                          </Box>
                          <Typography sx={modalStyle.planPriceDescription}>
                            Up to{" "}
                            <span style={modal3Style.cptCodeText}>
                              Unlimited CPT Code
                            </span>
                          </Typography>
                        </Box>
                        <Divider style={modalStyle.dividerColor} />
                        <Box padding={"24px"}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginBottom={"23px"}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              marginBottom={"8px"}
                            >
                              <img src={imgCheck} />
                              <Typography sx={modal3Style.mainTextDescription}>
                                Access toYear Over Year Comparison.
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                              <img src={imgCheck} />
                              <Typography sx={modal3Style.mainTextDescription}>
                                Access to Similar CPT Codes.
                              </Typography>
                            </Box>
                          </Box>
                          <Box padding={"0px 20px"}>
                            <Button
                              variant="outlined"
                              sx={modal3Style.continueModalButton}
                              fullWidth
                              data-test-id="premiumPlanContinue"
                              onClick={() =>
                                this.handleClickContinueButton(premiumPlan?.id)
                              }
                            >
                              Continue
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      paddingTop="34px"
                    >
                      <Typography
                        data-test-id="skipButton"
                        sx={styles.skipText}
                        onClick={() => this.handleSkipButton()}
                      >
                        Skip for now
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              <Modal
                open={isSkipForNowClicked}
                data-test-id="skipModalClose"
                onClose={(event, reason) => {
                  if (reason !== "backdropClick") {
                    this.handleSkipModalClose();
                  }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles.skipModalPlanInner}>
                  <Box sx={styles.skipModalInner}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography sx={styles.skipModalHeaderText}>
                        Congratulations,
                      </Typography>
                      <Typography sx={styles.skipModalHeaderText}>
                        your account has been successfully created.
                      </Typography>
                      <Box marginTop={"50px"}>
                        <img src={imgOk} />
                      </Box>
                      <Typography sx={styles.skipDescription}>
                        Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc vulputate libero et velit interdum, ac aliquet odio
                        mattis. Class aptent taciti sociosqu .
                      </Typography>
                      <Button
                        type="submit"
                        variant="contained"
                        data-test-id="loginSkipModal"
                        sx={styles.skipModalButton}
                        onClick={() => this.handleLoginNavigation()}
                      >
                        Log in
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              <Modal
                open={isPaymentDone}
                data-test-id="paymentDoneModalClose"
                onClose={(event, reason) => {
                  if (reason !== "backdropClick") {
                    this.handlePaymentDoneModalClose();
                  }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles.skipModalPlanInner}>
                  <Box sx={styles.skipModalInner}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Typography sx={styles.skipModalHeaderText}>
                        Congratulation! Your Payment has been Completed. 
                      </Typography>
                      <Box marginTop={"50px"}> 
                        <img src={imgOk} />
                      </Box>
                      <Typography sx={styles.skipDescription}>
                        Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc vulputate libero et velit interdum, ac aliquet odio
                        mattis. Class aptent taciti sociosqu .
                      </Typography>
                      <Button
                        type="submit"
                        variant="contained"
                        data-test-id="paymentDoneModal"
                        sx={styles.skipModalButton}
                        onClick={() => this.handleLoginNavigation()}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              <Modal
                open={isCardDetailsModal}
                data-test-id="cardDetailsModalClose"
                onClose={this.handleCardDetailsModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles.modalCardDetailsInner}>
                  <Box
                    display={"flex"}
                    justifyContent={"end"}
                    marginBottom={"10px"}
                  >
                    <img
                      src={imgClose}
                      style={styles.closeDeleteIcon}
                      data-test-id="cardDetailsModalCloseButton"
                      onClick={() => this.handleCardDetailsModalClose()}
                    />
                  </Box>
                  <Box sx={styles.cardDetailsModalInner}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        {this.renderPlanBox(planById, isPremiumPlanSelected)}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Formik
                          initialValues={initialValuesForCardDetails}
                          validationSchema={this.validationSchemaForCardDetails}
                          onSubmit={(values) => this.handleCardDetailsSubmit(values)}
                        >
                          {({ errors, touched, isValid, values, handleChange }) => (
                            <Form>
                              <Box padding="0px 40px">
                                <Grid container sx={styles.gridInnerPadding}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      marginBottom={"30px"}
                                    >
                                      <Typography
                                        sx={styles.billingHeader}
                                        textAlign="center"
                                      >
                                        Add Card Details
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Cardholder Name
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="cardholderName"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter Name"
                                        fullWidth
                                        sx={styles.cardModalFieldStyle}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <img src={imgCard} />
                                            </InputAdornment>
                                          ),
                                        }}
                                        error={
                                          getErrorAndHelperText(
                                            "cardholderName",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "cardholderName",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Card Number
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="cardNumber"
                                        variant="outlined"
                                        type="text"
                                        data-test-id="cardNumberTextField"
                                        placeholder="Enter Card Number"
                                        fullWidth
                                        value={values.cardNumber}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          this.onCardNumberChange();
                                          const inputValue = event.target.value.replace(/\s+/g, "");
                                          const formattedValue = inputValue.match(/.{1,4}/g)?.join(" ") || inputValue;
                                          handleChange("cardNumber")({
                                            ...event,
                                            target: {
                                              ...event.target,
                                              value: formattedValue,
                                            },
                                          });
                                        }}
                                        helperText={
                                          getErrorAndHelperText(
                                            "cardNumber",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            cardNumberError
                                          ).helperText
                                        }
                                        sx={
                                          styles.cardModalFieldStyle
                                        }
                                        error={
                                          getErrorAndHelperText(
                                            "cardNumber",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            cardNumberError
                                          ).isError
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={styles.gridInnerPaddingRight}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Expiration
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="expiration"
                                        variant="outlined"
                                        type="text"
                                        placeholder="MM/YYYY"
                                        fullWidth
                                        data-test-id="expirationTextField"
                                        value={values.expiration}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          handleChange("expiration")(event);
                                          this.onExpirationChange();
                                        }}
                                        sx={
                                          styles.cardModalFieldStyle
                                        }
                                        error={
                                          getErrorAndHelperText(
                                            "expiration",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            expirationError
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "expiration",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            expirationError
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={styles.gridInnerPaddingLeft}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        CVV
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        variant="outlined"
                                        name="cvv"
                                        placeholder="Enter CVV"
                                        type="password"
                                        fullWidth
                                        data-test-id="cvvTextField"
                                        value={values.cvv}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          handleChange("cvv")(event);
                                          this.onCvvChange();
                                        }}
                                        helperText={
                                          getErrorAndHelperText(
                                            "cvv",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            cvvError
                                          ).helperText
                                        }
                                        error={
                                          getErrorAndHelperText(
                                            "cvv",
                                            errors as FormError,
                                            touched as FormErrorTouched,
                                            cvvError
                                          ).isError
                                        }
                                        sx={{
                                          ...styles.cardModalFieldStyle,
                                          "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                            "&::-webkit-outer-spin-button": {
                                              "-webkit-appearance": "none",
                                              margin: 0,
                                            },
                                            "&::-webkit-inner-spin-button": {
                                              "-webkit-appearance": "none",
                                              margin: 0,
                                            },
                                          },
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Country
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="country"
                                        type="text"
                                        variant="outlined"
                                        placeholder="Enter Country Name"
                                        sx={styles.cardModalFieldStyle}
                                        fullWidth
                                        helperText={
                                          getErrorAndHelperText(
                                            "country",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                        error={
                                          getErrorAndHelperText(
                                            "country",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      sx={styles.continueWithPaymentButton}
                                      fullWidth
                                      disabled={!isValid}
                                    >
                                      Continue with Payment
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Modal>
              <Modal
                open={isAdressModal}
                data-test-id="addressModalClose"
                onClose={this.handleAddressModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles.modalCardDetailsInner}>
                  <Box
                    display={"flex"}
                    justifyContent={"end"}
                    marginBottom={"10px"}
                  >
                    <img
                      src={imgClose}
                      style={styles.closeDeleteIcon}
                      data-test-id="addressModalCloseButton"
                      onClick={() => this.handleAddressModalClose()}
                    />
                  </Box>
                  <Box sx={styles.cardDetailsModalInner}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        {this.renderPlanBox(planById, isPremiumPlanSelected)}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Formik
                          initialValues={initialValuesForAddress}
                          validationSchema={this.validationSchemaForAddress}
                          onSubmit={(values) => this.handleAddressSubmit(values)}
                        >
                          {({ errors, touched, isValid }) => (
                            <Form>
                              <Box padding="0px 40px">
                                <Grid container sx={styles.gridInnerPadding}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      marginBottom={"30px"}
                                    >
                                      <Typography
                                        sx={styles.billingHeader}
                                        textAlign="center"
                                      >
                                        Add Address Details
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Address
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="address"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter Address"
                                        fullWidth
                                        sx={styles.cardModalFieldStyle}
                                        error={
                                          getErrorAndHelperText(
                                            "address",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "address",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        City
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="city"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter City"
                                        fullWidth
                                        sx={styles.cardModalFieldStyle}
                                        error={
                                          getErrorAndHelperText(
                                            "city",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "city",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={styles.gridInnerPaddingRight}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        State
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="state"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter State"
                                        fullWidth
                                        sx={styles.cardModalFieldStyle}
                                        error={
                                          getErrorAndHelperText(
                                            "state",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "state",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={styles.gridInnerPaddingLeft}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        ZIP
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        name="zip"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter Zip"
                                        fullWidth
                                        sx={
                                          styles.cardModalFieldStyle
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "zip",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                        error={
                                          getErrorAndHelperText(
                                            "zip",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <FormControl
                                      sx={styles.cardDetailsFormControl}
                                    >
                                      <FormLabel
                                        sx={styles.cardDetailsModalLabelText}
                                      >
                                        Country
                                      </FormLabel>
                                      <Field
                                        as={TextField}
                                        type="text"
                                        name="country"
                                        placeholder="Enter Country"
                                        sx={styles.cardModalFieldStyle}
                                        fullWidth
                                        variant="outlined"
                                        error={
                                          getErrorAndHelperText(
                                            "country",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).isError
                                        }
                                        helperText={
                                          getErrorAndHelperText(
                                            "country",
                                            errors as FormError,
                                            touched as FormErrorTouched
                                          ).helperText
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={styles.gridInnerPadding}
                                  >
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      sx={styles.continueWithPaymentButton}
                                      fullWidth
                                      disabled={!isValid}
                                    >
                                      Continue with Payment
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStylesConditioanal = (isPlanHovered: boolean) => ({
  boxBorder: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    borderRadius: "8px",
    border: "1px solid #94A3B8",
    backgroundColor: isPlanHovered ? "#1E293B" : "white",
  },
  planPriceModal: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    color: isPlanHovered ? "white" : "#334155",
  },
  planPriceUnitModal: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: isPlanHovered ? "white" : "#334155",
    marginTop: "18px",
  },
  cptCodeText:{
    color: isPlanHovered ? "white" : "black",
  },
  mainTextDescription:{
    fontSize: "14px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: isPlanHovered ? "white" : "#9795B5",
    textAlign: "center"
  },
  continueModalButton: {
    cursor: 'pointer',
    backgroundColor: isPlanHovered ? "#1799CB" : '#fff',
    color: isPlanHovered ? "white" : '#94A3B8',
    borderRadius: '8px',
    border: isPlanHovered ? "1px solid #1799CB" : "1px solid #94A3B8",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: isPlanHovered ? "#1799CB" : '#fff',
    },
  },
});
const modalStyles = () => ({
  dividerColor:{
    backgroundColor: '#D4D2E3',
  },
  planPriceDescription:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#94A3B8",
  },
  freePlanText:{
    fontSize: "30px",
    fontWeight: 500,
    color: "#94A3B8",
    marginBottom: "16px",
  },
  premiumPlanText:{
    fontSize: "30px",
    fontWeight: 500,
    color: "#F69544",
    marginBottom: "16px",
  },
  planModalInner:{
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "60px 60px 34px"
  },
  modalPlanInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1284px",
    transform: "Translate(-50%, -50%)",
  },
})
const styles = {
  skipModalButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    width: "35%",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
  },
  skipDescription:{
    fontSize: "24px",
    fontWeight: 400,
    color: "#64748B",
    textAlign: "center",
    marginTop: "50px",
  },
  skipModalHeaderText:{
    fontSize: "30px",
    fontWeight: 700,
    color: "black",
    textAlign: "center",
  },
  skipModalInner:{
    borderRadius: "6px",
    backgroundColor: "#F9F9FF",
    padding:"80px 175px",
  },
  skipModalPlanInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1100px",
    transform: "Translate(-50%, -50%)",
  },
  skipText:{
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    color: "#94A3B8",
  },
  continueWithPaymentButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  cardModalFieldStyle:{
    backgroundColor: "#fff",
    '& .MuiFormHelperText-root': {
      margin: 0,
      backgroundColor: "white",
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft : "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  cardDetailsModalLabelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  gridInnerPaddingLeft:{
    padding: "0px !important",
    paddingLeft: "10px !important",
  },
  gridInnerPaddingRight:{
    padding: "0px !important",
    paddingRight: "10px !important",
  },
  cardDetailsFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  billingHeader:{
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  gridInnerPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
  },
  cardModalMainTextDescription:{
    fontSize: "14px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: "white",
    textAlign: "center"
  },
  cardModalCptCodeText:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#F8FAFC",
  },
  cardModalPlanPriceDescription:{
    fontSize: "16px",
    fontWeight: 300,
    color: "#94A3B8",
  },
  cardModalPlanPriceText:{
    color:"#F8FAFC",
    fontSize: "48px",
    fontWeight: 500,
    marginBottom: "10px",
  },
  cardModalPlanText:{
    marginBottom: "12px",
    fontSize: "32px",
    fontWeight: 700,
  },
  planBox: {
    height: "50%",
    backgroundColor: '#1E293B',
    color: 'white',
    padding: '150px 50px',
    borderRadius: '8px',
  },
  cardDetailsModalInner:{
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "50px"
  },
  modalCardDetailsInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1200px",
    transform: "Translate(-50%, -50%)",
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  seePricingButton:{
    cursor: 'pointer',
    position: "absolute",
    top: "80%",
    width:"25%",
    padding: "10px 16px",
    color: "white",
    textTransform: "none",
    backgroundColor: "#1799CB",
    "&:hover": {
      backgroundColor: "#1799CB",
      color: "white",
    },
  },
  videoIconButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    transition: "opacity 0.3s",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
  modalInner: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "800px",
    transform: "Translate(-50%, -50%)",
  },
  passwordRequirementBox: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0px",
  },
  successfullyCreatePasswordBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0px 40px",
  },
  successfullyCreatePasswordText: {
    fontFamily: "Tiempos Headline",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "40px",
    marginTop: "30px",
    marginBottom: "30px",
    color: "#1C1917",
    textAlign: "center",
  },
  errorText: {
    color: "#0F172A",
    marginLeft: "7px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  continueButton: {
    cursor: "pointer",
    backgroundColor: "#1799CB",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1799CB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#94A3B8",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#94A3B8",
        color: "#ffffff",
      },
    },
  },
  iconColor: {
    color: "#94A3B8",
  },
  fieldStyleCreatePassword: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    "& .MuiInputBase-input": {
      color: "#94A3B8",
      paddingLeft: "5px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        borderColor: "#CBD5E1",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
      },
    },
  },
  createPasswordFormControlPassword: {
    width: "100%",
    marginBottom: "20px",
  },
  labelTextCreatePassword: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A",
    marginBottom: "5px",
  },
  createPasswordForm: {
    width: "100%",
  },
  createPasswordText: {
    fontFamily: "Tiempos Headline",
    fontSize: "30px",
    lineHeight: "40px",
    color: "#1C1917",
    marginBottom: "20px",
    fontWeight: "700",
  },
  formContainerCreatePassword: {
    alignItems: "center",
    height: "100%",
    padding: "0 120px",
    "@media (max-width:699px)": {
      padding: "0px",
    },
    "@media (max-width:1199px)": {
      padding: "0 100px",
    },
    "@media (max-width:799px)": {
      padding: "0px 20px",
    },
    "@media (max-width:1099px)": {
      padding: "0 80px",
    },
    "@media (max-width:899px)": {
      marginTop: "20px",
      padding: "0px 40px",
    },
    "@media (max-width:999px)": {
      padding: "0 60px",
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  logoCreatePassword: {
    height: "50px",
    position: "absolute",
    width: "50px",
    left: "25px",
    top: "25px",
  },
  imageCreatePassword: {
    width: "100%",
    height: "100%",
  },
  imageWrapperCreatePassword: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  createPasswordMainContainer: {
    "@media (max-width:899px)": {
      overflow: "visible",
      padding: "34px",
      height: "100%",
    },
    margin: "34px",
    overflow: "hidden",
    height: "100%",
  },
  mainGridCreatePassword: {
    marginLeft: 0,
    width: "100%",
    height: "100%",
    marginTop: 0,
  },
  secondGridCreatePassword: {
    paddingTop: "0 !important",
    height: "100%",
    paddingLeft: "0 !important",
  },
  imageContainerCreatePassword: {
    position: "relative",
    width: "100%",
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
    height: "100%",
  },
};
// Customizable Area End
